<template>
  <v-container grid-list-md class="pa-6">
    <v-row justify="space-around">
      <v-col md="12">
        <h1 class="text-h4 mb-3 font-weight-light">
          {{ $t('privacyPolicy.document.title') }}
        </h1>
        <h4 class="mb-3">{{ $t('privacyPolicy.lastUpdateDate') }}</h4>
        <p class="mb-3">
          {{ $t('privacyPolicy.document.first') }}
        </p>
        <p class="mb-3" v-html="$t('privacyPolicy.document.second')"></p>

        <div class="mb-3">
          <h3>
            {{ $t('privacyPolicy.document.infoWeCollect.title') }}
          </h3>
          <p>
            <strong>
              {{
                $t(
                  'privacyPolicy.document.infoWeCollect.accountRegistration.title'
                )
              }}
            </strong>
            <span>{{
              $t(
                'privacyPolicy.document.infoWeCollect.accountRegistration.text'
              )
            }}</span>
          </p>

          <p>
            <strong>
              {{
                $t(
                  'privacyPolicy.document.infoWeCollect.paymentInformation.title'
                )
              }}
            </strong>
            <span
              v-html="
                $t(
                  'privacyPolicy.document.infoWeCollect.paymentInformation.text'
                )
              "
            ></span>
          </p>

          <p>
            <strong>
              {{
                $t(
                  'privacyPolicy.document.infoWeCollect.contactInformation.title'
                )
              }}
            </strong>
            <span>{{
              $t('privacyPolicy.document.infoWeCollect.contactInformation.text')
            }}</span>
          </p>

          <p>
            <strong>
              {{
                $t(
                  'privacyPolicy.document.infoWeCollect.technicalInfomation.title'
                )
              }}
            </strong>
          </p>
          <ul class="mb-3">
            <li>
              {{
                $t(
                  'privacyPolicy.document.infoWeCollect.technicalInfomation.text.first'
                )
              }}
            </li>
            <li>
              {{
                $t(
                  'privacyPolicy.document.infoWeCollect.technicalInfomation.text.second'
                )
              }}
            </li>
          </ul>

          <p>
            <strong>
              {{
                $t(
                  'privacyPolicy.document.infoWeCollect.geolocationInformation.title'
                )
              }}
            </strong>
            <span>{{
              $t(
                'privacyPolicy.document.infoWeCollect.geolocationInformation.text'
              )
            }}</span>
          </p>
        </div>

        <div class="mb-3">
          <h3>
            {{ $t('privacyPolicy.document.howWeUseInformation.title') }}
          </h3>

          <ul class="mb-3">
            <li>
              {{ $t('privacyPolicy.document.howWeUseInformation.first') }}
            </li>
            <li>
              {{ $t('privacyPolicy.document.howWeUseInformation.second') }}
            </li>
            <li>
              {{ $t('privacyPolicy.document.howWeUseInformation.third') }}
            </li>
            <li>
              {{ $t('privacyPolicy.document.howWeUseInformation.fourth') }}
            </li>
            <li>
              {{ $t('privacyPolicy.document.howWeUseInformation.fifth') }}
            </li>
          </ul>
        </div>

        <div class="mb-3">
          <h3>
            {{ $t('privacyPolicy.document.howWeShareInformation.title') }}
          </h3>

          <p>
            <strong>
              {{
                $t(
                  'privacyPolicy.document.howWeShareInformation.aggregateInfo.title'
                )
              }}
            </strong>
            <span>{{
              $t(
                'privacyPolicy.document.howWeShareInformation.aggregateInfo.text'
              )
            }}</span>
          </p>

          <p>
            <strong>
              {{
                $t(
                  'privacyPolicy.document.howWeShareInformation.advertising.title'
                )
              }}
            </strong>
            <span>{{
              $t(
                'privacyPolicy.document.howWeShareInformation.advertising.text'
              )
            }}</span>
          </p>

          <p>
            <strong>
              {{
                $t('privacyPolicy.document.howWeShareInformation.consent.title')
              }}
            </strong>
            <span>{{
              $t('privacyPolicy.document.howWeShareInformation.consent.text')
            }}</span>
          </p>
        </div>

        <div class="mb-3">
          <h3>
            {{ $t('privacyPolicy.document.security.title') }}
          </h3>

          <p>
            {{ $t('privacyPolicy.document.security.text') }}
          </p>
        </div>

        <div class="mb-3">
          <h3>
            {{ $t('privacyPolicy.document.dataProtectionRights.title') }}
          </h3>

          <ul class="mb-3">
            <li
              v-html="$t('privacyPolicy.document.dataProtectionRights.first')"
            ></li>
            <li
              v-html="$t('privacyPolicy.document.dataProtectionRights.second')"
            ></li>
            <li
              v-html="$t('privacyPolicy.document.dataProtectionRights.third')"
            ></li>
            <li>
              {{ $t('privacyPolicy.document.dataProtectionRights.fourth') }}
            </li>
          </ul>
        </div>

        <div class="mb-3">
          <h3>
            {{ $t('privacyPolicy.document.childrenInfoSection.title') }}
          </h3>

          <p
            v-html="$t('privacyPolicy.document.childrenInfoSection.first')"
          ></p>

          <p
            v-html="$t('privacyPolicy.document.childrenInfoSection.second')"
          ></p>
        </div>

        <div class="mb-3">
          <h3>
            {{ $t('privacyPolicy.document.onlinePoliciesSection.title') }}
          </h3>

          <p>
            {{ $t('privacyPolicy.document.onlinePoliciesSection.text') }}
          </p>
        </div>

        <div class="mb-3">
          <h3>
            {{ $t('privacyPolicy.document.consentSection.title') }}
          </h3>

          <p>
            {{ $t('privacyPolicy.document.consentSection.text') }}
          </p>
        </div>

        <div class="mb-3">
          <h3>
            {{ $t('privacyPolicy.document.contactingUsSection.title') }}
          </h3>

          <p v-html="$t('privacyPolicy.document.contactingUsSection.text')"></p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'privacy-policy'
};
</script>
